import React from 'react'
import EmailTest from '../../components/Email/Test'
import FSRoute from '../../components/FSRoute'
import Layout from '../../components/Layout'
import { isBrowser } from '../../utils/misc'
const App = ({ data }) => {
  if (!isBrowser) return null
  return (
    <Layout>
      <FSRoute
        path="/email/test"
        component={EmailTest}
        fsOnly="false"
        data={data}
      />
    </Layout>
  )
}

export default App
