import React from 'react'
import * as styles from './form.module.css'
import RichTextEditor from '../Email/RichTextEditor'
import WaitLoader from '../WaitLoader'
import Moment from 'moment'
import BestPracticeLink from './best-practices'

const Email = (({
  to,
  handleSubmit,
  handleUpdate,
  addressesChecked,
  handleAddressCheck,
  subject,
  userMessage,
  showUserImage,
  recipientRows,
  deliverabilityChecked,
  emailValidationMessage,
  emailValidMessage,
  emailsNotDeliverable,
  checkingDeliverability
}) => (
  <form
    className={styles.form}
  >
    <div style={{position: 'relative'}}>
    <label className={styles[`form__label`]}>
      Email To&nbsp;
      <span className={styles[`form__labelsmall`]}>
      (You can only send up to 10 email addresses. One line per e-mail.)</span>
      <textarea
        className={styles['form__input']}
        name="to"
        value={to}
        onChange={handleUpdate}
      />
    </label>
    <div className="row">
    <div className="col-xs-8" style={{
      textAlign: 'left'
    }}>
    {!deliverabilityChecked &&
      <div className="error">Please check emails for targetability before proceeding.</div>
    }
    <div className="error">{emailValidationMessage}</div>
    <div className="error">{
      emailsNotDeliverable.map(function(item, i){
        if(item.LastReceived){
          return <div>
          {item.Id} last received on
          {Moment(item.LastReceived).format('MM/DD/YYYY')}</div>
        }else{
          return <div>{item.Id} is invalid</div>
        }
      })
    }</div>
    <div className="valid">{emailValidMessage}</div>
    </div>
    <button className="button green"
    disabled={addressesChecked}
    onClick={handleAddressCheck}
    style={{
      marginRight: '0px'
    }}>
    Check Targetability
    </button>
    </div>
    </div>
    <WaitLoader loading={checkingDeliverability} />
    <div className="col-xs-4" style={{
      textAlign: 'right'
    }}>
    </div>
    <label className={styles[`form__label`]}>
      Subject Line
      <input
        className={styles[`form__input`]}
        type="text"
        name="subject"
        value={subject}
        placeholder="TYPE AN ENTICING SUBJECT LINE"
        onChange={handleUpdate}
      />
    </label>
    <BestPracticeLink />
    <label className={styles[`form__label`]} htmlFor="user-message">
      Intro Message
    </label>
    <RichTextEditor
    id="user-message"
    name="userMessage"
    body={userMessage}
    placeholder="TYPE AN ENGAGING INTRO MESSAGE"
    onUpdate={(value) => handleUpdate({target: {
      name: 'userMessage',
      value: value
    }})}
    />
    <label className={styles[`form__label`] + ' ' + styles[`for__checkbox`]}>
    Show User Image (if available)
    <input
    className={styles[`form__input__checkbox`]}
    type="checkbox"
    name="showUserImage"
    checked={showUserImage}
    onChange={(e) => handleUpdate({target: {
          name: 'showUserImage',
          value: e.target.checked
        }})}
    />
    </label>

  </form>
))

export default Email
